import React, { Component } from 'react';
import './App.css';
import { Switch, Route, withRouter } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from 'react-localize-redux';
import { isBrowser } from 'react-device-detect';

import ResetScroll from './components/reset_scroll/ResetScroll'

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Locations from './components/pages/locations/Locations';
import Location from './components/pages/locations/location/Location';
import Waterloo from './components/pages/locations/location/Waterloo';
import Guelph from './components/pages/locations/location/Guelph';
import RedDeer from './components/pages/locations/location/Red Deer';
import Plano from './components/pages/locations/location/Plano';

import BirthdaysWaterloo from './components/pages/packages/Birthdays Waterloo';
import BirthdaysGuelph from './components/pages/packages/Birthdays Guelph';
import BirthdaysRedDeer from './components/pages/packages/Birthdays Red Deer';
import BirthdaysPlano from './components/pages/packages/Birthdays Plano';

import EventsWaterloo from './components/pages/packages/Events Waterloo';
import EventsGuelph from './components/pages/packages/Events Guelph';
import EventsRedDeer from './components/pages/packages/Events Red Deer';
import EventsPlano from './components/pages/packages/Events Plano';

import Home from './components/pages/home/Home';
import Extralife from './components/pages/extra_life/Extralife';
import Games from './components/pages/games/Games';
import GiftCards from './components/pages/gift_cards/GiftCards';
import FreeVR from './components/pages/free_vr/FreeVR';
import Referral from './components/pages/referral/Referral';
import LiveStreamPromo from './components/pages/live_stream_promo/LiveStreamPromo';
import Loyalty from './components/pages/loyalty/Loyalty';
import WaiverAndVideo from './components/pages/waiver_and_video/WaiverAndVideo';
import Faq from './components/pages/frequently_asked_questions/Faq';
import About from './components/pages/about/About';
import LocationMap from './components/pages/location_map/LocationMap';
import Legal from './components/pages/legal/Legal';
import SmashboxLeague from './components/pages/smashbox_league/SmashboxLeague';
import Pressroom from './components/pages/pressroom/Pressroom';
import PressroomPost from './components/pages/pressroom/PressroomPost';
import BookNowPopup from './components/book_now_popup/BookNowPopup';
import CookiesPopup from './components/cookies_popup/CookiesPopup';
import Events from './components/pages/packages/Events';
import Birthdays from './components/pages/packages/Birthdays';
import UnsupportedBrowser from './components/unsupported_broswer/UnsupportedBrowser';
import RedirectHandler from './components/redirect_handler/RedirectHandler';
import Search from './components/search/Search';
import EmailVerification from './components/pages/email_verification/EmailVerification';
import ReferralAccepted from './components/pages/referral_accepted/ReferralAccepted';
import Unsubscribe from './components/pages/unsubscribe/Unsubscribe';
// import MetaHandler from './components/web_meta_handler/MetaHandler';
import CustomerContact from './components/customer_chat/customer_contact';
import AnniVRsary from './components/pages/annivrsary/Annivrsary';
import Seniors from './components/pages/seniors/Seniors';
import Careers from './components/pages/careers/Careers';
import EscapeRooms from './components/pages/escape_rooms/EscapeRooms';
import PlanoHomeschool from './components/pages/homeschool/PlanoHomeschool';
import VictoryXR from './components/pages/homeschool/VictoryXR';

class App extends Component {

	constructor(props) {
		super(props);

		this.props.initialize({
			languages: [
				{ name: "English", code: "en" },
				{ name: "Français", code: "fr" }
			],
			options: { renderToStaticMarkup }
		});
	}

	componentDidUpdate() {
		let getUrl = window.location;
		let baseUrl = getUrl.protocol + "//" + getUrl.host;
		let path = baseUrl + this.props.location.pathname;
	}

	checkQueryParams() {
		const search = window.location.search;
		const query = search.split('?')[1];
		if (!query) return;
		const noheaderfooter = query.match('noheaderfooter') !== null;
		this.noheaderfooter = noheaderfooter;
	}

	render() {
		this.checkQueryParams();
		return (
			<React.Fragment>
				{/* <MetaHandler></MetaHandler> */}
				<ResetScroll>
					{/* <div className={isBrowser ? "site-wrapper" : "mobile-site-wrapper"} style={{ backgroundImage: `url(${background}.${getExtension()})` }} > */}
					<div className={isBrowser ? "site-wrapper" : "mobile-site-wrapper"} >
						{!this.noheaderfooter ? <Header /> : null}
						{!this.noheaderfooter ? <BookNowPopup /> : null}
						{!this.noheaderfooter ? <UnsupportedBrowser /> : null}
						<main id="main">
							<div className={isBrowser ? "index-wrapper" : "mobile-index-wrapper"} >
								<Switch>
									<Route path="/" exact render={() => <Home />} />
									<Route path="/games/:id" render={() => <Games />} />
									<Route path="/locations/" render={() => <Locations />} />
									<Route path="/location/waterloo" render={() => <Waterloo />} />
									<Route path="/location/guelph" render={() => <Guelph />} />
									<Route path="/location/red-deer" render={() => <RedDeer />} />
									<Route path="/location/plano" render={() => <Plano />} />
									<Route path="/birthdays/waterloo" render={() => <BirthdaysWaterloo />} />
									<Route path="/birthdays/guelph" render={() => <BirthdaysGuelph />} />
									<Route path="/birthdays/red-deer" render={() => <BirthdaysRedDeer />} />
									<Route path="/birthdays/plano" render={() => <BirthdaysPlano />} />
									<Route path="/events/waterloo" render={() => <EventsWaterloo />} />
									<Route path="/events/guelph" render={() => <EventsGuelph />} />
									<Route path="/events/red-deer" render={() => <EventsRedDeer />} />
									<Route path="/events/plano" render={() => <EventsPlano />} />
									{/* <Route path="/location/:id" render={() => <Location />} />
									<Route path="/events/:id" render={() => <Events />} />
									<Route path="/birthdays/:id" render={() => <Birthdays />} /> */}
									<Route path="/gift-cards/" render={() => <GiftCards />} />
									<Route path="/giftcards/" render={() => <GiftCards />} />
									<Route path="/free-VR/" render={() => <FreeVR />} />
									<Route path="/referral/" render={() => <Referral />} />
									<Route path="/live-stream-promo/" render={() => <LiveStreamPromo />} />
									<Route path="/loyalty/" render={() => <Loyalty />} />
									<Route path="/online-waiver/" render={() => <WaiverAndVideo />} />
									<Route path="/faq/" render={() => <Faq />} />
									<Route path="/about/" render={() => <About />} />
									<Route path="/location-map/" render={() => <LocationMap />} />
									<Route path="/legal/" render={() => <Legal />} />
									<Route path="/smashbox-league/" render={() => <SmashboxLeague />} />
									<Route path="/pressroom" exact render={() => <Pressroom />} />
									<Route path="/pressroom/:id" render={() => <PressroomPost />} />
									<Route path="/extra-life" render={() => <Extralife />} />
									<Route path="/annivrsary/" render={() => <AnniVRsary />} />
									<Route path="/seniors/" render={() => <Seniors />} />
									<Route path="/careers/" render={() => <Careers />} />
									<Route path="/escape-rooms/" render={() => <EscapeRooms />} />
									<Route path="/plano-homeschool/" render={() => <PlanoHomeschool />} />
									<Route path="/victoryxr/" render={() => <VictoryXR />} />

									<Route path="/email-verified/" render={() => <EmailVerification />} />
									<Route path="/referral-accepted/" render={() => <ReferralAccepted />} />
									<Route path="/unsubscribe/" render={() => <Unsubscribe />} />

									<Route path='/yeet' render={() => <Search />} />
									<Route path='/' render={() => <RedirectHandler />} />
									</Switch>
								</div>
						</main>
						<CustomerContact />
						{!this.noheaderfooter ? <CookiesPopup /> : null}
						<div className="footer-parallax-wrapper">
							{!this.noheaderfooter ? <Footer /> : null}
						</div>
					</div>
				</ResetScroll>
			</React.Fragment>
		);
	}
}

export default withLocalize(withRouter(App));
