import React from 'react';
import { useRef, useEffect } from 'react';
import loadScript from 'load-script2';
import {
    LOCAL_STORAGE,
    getLocalStorage
} from '../../utilities/GlobalConsts'

let initialized = false;
let queue = [];

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function fb(callback) {
    if (initialized) {
        callback(window.FB);
    } else {
        queue.push(callback);
        if (!window.fbAsyncInit) {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: 333753770682611,
                    autoLogAppEvents: true,
                    status: true,
                    cookie: true,
                    xfbml: false,
                    version: 'v3.2',
                });
                initialized = true;
                queue.forEach(cb => cb(window.FB));
                queue = null;
            };
            loadScript(`https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js`);
        }
    }
}

const FaceBookChat = (props) => {
    let [chatLocation, setChatLocation] = React.useState(1518661035096804);
    let [display, setDisplay] = React.useState(false);

    const onFunction = () => {
        props.onFunction();
        setDisplay(true);
    }

    const offFunction = () => {
        props.offFunction();
        setDisplay(false);
    }

    //Think of an way to solve the useEffect parameter issue
    React.useEffect(() => {
        let storedLocation = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY);
        setChatLocation(storedLocation.social_media.facebook_page_id);
        try {
            fb(FB => {
                FB.XFBML.parse()
                FB.Event.subscribe('customerchat.dialogShow', onFunction)
                FB.Event.subscribe('customerchat.dialogHide', offFunction)
            });
        } catch (err) {
            console.error(err);
        }

        try {
            if (props.mobile) {
                setTimeout(() => { fb(FB => { FB.CustomerChat.hideDialog() }) }, 1000)
            }
        } catch (err) {
            console.error(err);
        }
    }, [])

    useInterval(() => {
        let storedLocation = getLocalStorage(LOCAL_STORAGE.LOCATION_DETAILS.KEY)
        if (storedLocation && (storedLocation.social_media.facebook_page_id !== chatLocation || (storedLocation.social_media.facebook_page_id !== 1518661035096804 && chatLocation === 1518661035096804))) {
            setChatLocation(storedLocation.social_media.facebook_page_id);
            try {
                fb(FB => {
                    FB.XFBML.parse()
                    FB.Event.subscribe('customerchat.dialogShow', onFunction)
                    FB.Event.subscribe('customerchat.dialogHide', offFunction)
                });
            } catch (err) {
                console.error(err);
            }

            try {
                if (props.mobile) {
                    setTimeout(() => { fb(FB => { FB.CustomerChat.hideDialog() }) }, 1000)
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, 3000)

    if (display) {
        return (
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id={chatLocation}
                theme_color={"#0098DA"}
                logged_in_greeting={"Welcome to Ctrl V, do you have a question?"}
                logged_out_greeting={"Welcome to Ctrl V, do you have a question?"}
                greeting_dialog_display={"hide"}
                style={{ zIndex: "1000" }}
            />
        )

    } else {
        return (
            <div
                className="fb-customerchat"
                attribution="setup_tool"
                page_id={chatLocation}
                theme_color={"#0098DA"}
                logged_in_greeting={"Welcome to Ctrl V, do you have a question?"}
                logged_out_greeting={"Welcome to Ctrl V, do you have a question?"}
                greeting_dialog_display={"hide"}
                style={{ display: "none" }}
            />
        )
    }
}

export default FaceBookChat;
