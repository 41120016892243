import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { withLocalize, Translate } from 'react-localize-redux';
import NavTranslation from '../../../translations/navigation.json';

import BookNowModal from '../../book_now_modal/BookNowModal';

import './DesktopNav.css';

import {
    LOCAL_STORAGE,
    getBaseName
} from '../../../utilities/GlobalConsts';
//eslint-disable-next-line
import Search from '../../search/Search.js';

const DropDownArrow = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/dropdown_arrow_white.png';
const BlueDropDownArrow = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/dropdown_arrow_blue.png';

class DesktopNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            freeVrArrow: DropDownArrow,
            corporateArrow: DropDownArrow,
            waiverVideoArrow: DropDownArrow,
            myInfoArrow: DropDownArrow,
            locationsArrow: DropDownArrow,
            showModal:false
        }
        this.props.addTranslation(NavTranslation);

        this.tryRedirect = this.tryRedirect.bind(this);
        this.locationMatches = this.locationMatches.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    // mouse triggered event to show blue arrow icon for free vr heading
    freeVrMouseOver = () => {
        this.setState({
            freeVrArrow: BlueDropDownArrow
        });
    }

    // mouse triggered event to show white arrow icon for free vr heading
    freeVrMouseLeave = () => {
        this.setState({
            freeVrArrow: DropDownArrow
        });
    }

    // mouse triggered event to show blue arrow icon for corporate heading
    corporateMouseOver = () => {
        this.setState({
            corporateArrow: BlueDropDownArrow
        });
    }

    // mouse triggered event to show white arrow icon for corporate heading
    corporateMouseLeave = () => {
        this.setState({
            corporateArrow: DropDownArrow
        });
    }

    // mouse triggered event to show blue arrow icon for waiverVideo heading
    waiverVideoMouseOver = () => {
        this.setState({
            waiverVideoArrow: BlueDropDownArrow
        });
    }

    // mouse triggered event to show white arrow icon for waiverVideo heading
    waiverVideoMouseLeave = () => {
        this.setState({
            waiverVideoArrow: DropDownArrow
        });
    }

    // mouse triggered event to show blue arrow icon for myInfo heading
    myInfoMouseOver = () => {
        this.setState({
            myInfoArrow: BlueDropDownArrow
        });
    }

    // mouse triggered event to show white arrow icon for myInfo heading
    myInfoMouseLeave = () => {
        this.setState({
            myInfoArrow: DropDownArrow
        });
    }

    // mouse triggered event to show blue arrow icon for corperate heading
    locationsMouseOver = () => {
        this.setState({
            locationsArrow: BlueDropDownArrow
        });
    }

    // mouse triggered event to show white arrow icon for corperate heading
    locationsMouseLeave = () => {
        this.setState({
            locationsArrow: DropDownArrow
        });
    }

    clearSavedLocation = () => {
        window.localStorage.removeItem(LOCAL_STORAGE.LOCATION.KEY);
        window.localStorage.removeItem(LOCAL_STORAGE.LOCATION_DETAILS.KEY);
        this.props.history.push("/locations");
    }

    locationMatches = () =>{
        const url = window.location.href;
        const displayNameRe = new RegExp(`${this.props.locationDetails.webName}`, 'i');
        if(url.match('location/[a-z]+.*') === null ){ // If the user has no preferred location
            return true;
        }
        return displayNameRe.test(url.split('/location/')[1]);
    }

    handleCloseModal(){
        this.setState({
            showPopup:false
        })
    }

    tryRedirect(){
        if(!this.locationMatches()){
            console.warn("Location does not match");
            this.setState({
                showPopup:true
            });
        }
        else{
            window.location = `${getBaseName()}/booking/location/${this.props.locationDetails.location_id}`;
        }
    }
    getLocationMenuItem = () => {
        if(this.props.locationDetails) {
            return (
                <div>

                    <div
                        className="desktop-nav-item location-desktop-item"
                        onMouseOver={this.locationsMouseOver}
                        onMouseOut={this.locationsMouseLeave}
                        onClick={() => this.props.history.push(`/location/${this.props.locationDetails.webName}`)}>
                            {this.props.locationDetails.displayName}
                            <img src={this.state.locationsArrow} alt="Dropdown Arrow" className="dropdown-arrow"></img>
                    </div>

                    <div className="location-desktop-sub-item desktop-sub-item">
                        <ul>
                            {this.props.locationDetails.webName!=='mississauga'?
                            <li><HashLink to={`/birthdays/${this.props.locationDetails.webName}`}>
                                <Translate id="locations.birthdays" />
                            </HashLink></li>
                            :null
                            }
                            <li><HashLink to={`/location/${this.props.locationDetails.webName}/#calendar`}>
                                <Translate id="locations.calendar" />
                            </HashLink></li>
                            <li><HashLink to={`/location/${this.props.locationDetails.webName}/#games`}>
                                <Translate id="locations.games" />
                            </HashLink></li>
                            <li><HashLink to={`/escape-rooms`}>
                                <Translate id="locations.escape-rooms" />
                            </HashLink></li>
                            <li><HashLink to={`/events/${this.props.locationDetails.webName}/`}>
                                <Translate id="locations.events" />
                            </HashLink></li>
                            <li><a href={`${getBaseName()}/leaderboards`}>
                                <Translate id="locations.leaderboards" />
                            </a></li>
                            <li to="/locations" onClick={this.clearSavedLocation}>
                                <Translate id="change-location" />
                            </li>

                        </ul>
                    </div>
                </div>
            )
        } else {
            return (
                <Link to="/locations">
                    <div className="desktop-nav-item">
                        <Translate id="locations.main" />
                    </div>
                </Link>
            );
        }
    }

    getBookNowButton = () => {

        return (
            <div className="desktop-nav-book-now">
                {this.state.showPopup?<BookNowModal
                    closeModal={this.handleCloseModal}
                    optionOne={()=>{window.location = `${getBaseName()}/booking/location/${this.props.locationDetails.location_id}`}}
                    optionTwo={()=>{window.location = `${getBaseName()}/booking/`}}
                    preferredLocation={this.props.locationDetails.displayName}
                    />:null}
                <div onClick={()=>{this.tryRedirect()}}>
                    <button className="heading-book-now">
                        <Translate id="book-now" />
                    </button>
                </div>
            </div>
        )
    }

    render() {
        return(
            <div className="desktop-nav-wrapper">
                {this.getBookNowButton()}

                {this.getLocationMenuItem()}

                <Link to="/gift-cards">
                    <div className="desktop-nav-item">
                        <Translate id="gift-cards" />
                    </div>
                </Link>

                <Link to="/extra-life">
                    <div className="desktop-nav-item">
                        <Translate id="extra-life" />
                    </div>
                </Link>

                <div>
                    <div className="desktop-nav-item free-vr-desktop-item" onMouseOver={this.freeVrMouseOver} onMouseOut={this.freeVrMouseLeave}>
                        <Translate id="free-vr.title" />
                        <img src={this.state.freeVrArrow} width="14" height="14" alt="Dropdown Arrow" className="dropdown-arrow"></img>
                    </div>

                    <div className="free-vr-desktop-sub-item desktop-sub-item">
                        <ul>
                            <li><Link to="/free-VR">
                                <Translate id="free-vr.demo-day" />
                            </Link></li>

                            <li><Link to="/referral">
                                <Translate id="free-vr.referral" />
                            </Link></li>

                            <li><Link to="/loyalty">
                                <Translate id="free-vr.loyalty-program" />
                            </Link></li>

                            <li><Link to="/seniors">
                                <Translate id="free-vr.seniors" />
                            </Link></li>

                            <li><Link to="/live-stream-promo">
                                <Translate id="free-vr.live-stream-promo" />
                            </Link></li>
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="desktop-nav-item waiver-video-desktop-item" onMouseOver={this.waiverVideoMouseOver} onMouseOut={this.waiverVideoMouseLeave}>
                        <Translate id="waiver-video.title" />
                        <img src={this.state.waiverVideoArrow} width="14" height="14" alt="Dropdown Arrow" className="dropdown-arrow"></img>
                    </div>
                    <div className="waiver-video-desktop-sub-item desktop-sub-item">
                        <ul>
                            <li><a href={`${getBaseName()}/waiver`} target="_blank" rel="noopener noreferrer">
                                <Translate id="waiver-video.waiver" />
                            </a></li>
                            <li><a href="https://youtu.be/AIYXxu950tE" target="_blank" rel="noopener noreferrer">
                                <Translate id="waiver-video.video" />
                            </a></li>
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="desktop-nav-item my-info-desktop-item" onMouseOver={this.myInfoMouseOver} onMouseOut={this.myInfoMouseLeave}>
                        <Translate id="my-info.title" />
                        <img src={this.state.myInfoArrow} width="14" height="14" alt="Dropdown Arrow" className="dropdown-arrow"></img>
                    </div>
                    <div className="my-info-desktop-sub-item desktop-sub-item">
                        <ul>
                            <li><a href={`${getBaseName()}/accounts/login`} target="_blank" rel="noopener noreferrer">
                                <Translate id="my-info.account-ctrl" />
                            </a></li>
                            <li><a href={`${getBaseName()}/move-booking/`} target="_blank" rel="noopener noreferrer">
                                <Translate id="my-info.move-booking" />
                            </a></li>
                        </ul>
                    </div>
                </div>

                { !this.props.hideCorperate ?
                    <a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer">
                        <div className="desktop-nav-item">
                            <Translate id="franchising" />
                        </div>
                    </a>
                    : null
                }

                <div>
                    <div className="desktop-nav-item corporate-desktop-item" onMouseOver={this.corporateMouseOver} onMouseOut={this.corporateMouseLeave}>
                        <Translate id="corporate.title" />
                        <img src={this.state.corporateArrow} width="14" height="14" alt="Dropdown Arrow" className="dropdown-arrow"></img>
                    </div>
                    <div className="corporate-desktop-sub-item desktop-sub-item">
                        <ul>
                            <li><Link to="/about">
                                <Translate id="corporate.about" />
                            </Link></li>
                            <li><Link to="/location-map">
                                <Translate id="corporate.location-map" />
                            </Link></li>
                            <li><Link to="/pressroom">
                                <Translate id="corporate.pressroom" />
                            </Link></li>
                            <div>
                                <li className="legal-desktop-sub-item desktop-sub-item">
                                    <Translate id="corporate.legal.title" />
                                    <img src={DropDownArrow} width="14" height="14" alt="Dropdown Arrow" className="sub-menu-dropdown"></img>
                                </li>
                                <div className="legal-desktop-sub-menu">
                                    <ul>
                                        <li><Link to="/legal/terms-of-use">
                                            <Translate id="corporate.legal.terms-of-use" />
                                        </Link></li>
                                        <li><Link to="/legal/privacy-policy">
                                            <Translate id="corporate.legal.privacy-policy" />
                                        </Link></li>
                                        <li><Link to="/legal/disclaimer">
                                            <Translate id="corporate.legal.disclaimer" />
                                        </Link></li>
                                        <li><Link to="/legal/copyright">
                                            <Translate id="corporate.legal.copyright" />
                                        </Link></li>
                                        <li><Link to="/legal/anti-spam">
                                            <Translate id="corporate.legal.anti-spam" />
                                        </Link></li>
                                        <li><Link to="/legal/cookies">
                                            <Translate id="corporate.legal.cookies" />
                                        </Link></li>
                                        <li><Link to="/legal/linking">
                                            <Translate id="corporate.legal.linking" />
                                        </Link></li>
                                        <li><Link to="/legal/ctrl-v-rules">
                                            <Translate id="corporate.legal.rules" />
                                        </Link></li>
                                        <li><Link to="/legal/terms-and-conditions">
                                            <Translate id="corporate.legal.terms-and-conditions" />
                                        </Link></li>
                                        <li><Link to="/legal/media-release">
                                            <Translate id="corporate.legal.media-release" />
                                        </Link></li>
                                        <li><Link to="/legal/contest-rules">
                                            <Translate id="corporate.legal.contest-rules" />
                                        </Link></li>
                                        <li><Link to="/legal/sweepstakes-rules">
                                            <Translate id="corporate.legal.sweepstakes-rules" />
                                        </Link></li>
                                    </ul>
                                </div>

                                { this.props.hideCorperate ?
                                    <li><a href="https://www.virtualrealityfranchise.com/" target="_blank" rel="noopener noreferrer">
                                        <Translate id="franchising" />
                                    </a></li>
                                    : null
                                }
                            </div>
                        </ul>
                    </div>
                </div>

                <Link to="/faq">
                    <div className="desktop-nav-item">
                        <Translate id="faq" />
                    </div>
                </Link>
                <Search />
            </div>
        );
    }
}

export default withLocalize(withRouter(DesktopNav));
