import React from 'react';
import './FacilityHours.css';
import moment from 'moment';

const CLOSED_TEXT = "CLOSED ...BUT STILL AWESOME"

const OpHoursTable = props => { // simple component to display opHours
  const opHours = props.opHours; // array of {}
  const rows = opHours.map((dowHours, i) => {
    return (
      <p className="facility-hour-padding" key={i}><span style={{color: '#0098da', fontWeight: "bold"}}>{dowHours.dow}: </span>{dowHours.hours}</p>
    );
  });

  return (
    <div className="OpHoursTable">
      {rows}
    </div>
  );
}

export default class FacilityHours extends React.Component {
  state = { // initialize states
    opHours: [
      {dow: "Sunday", hours: CLOSED_TEXT},
      {dow: "Monday", hours: CLOSED_TEXT},
      {dow: "Tuesday", hours: CLOSED_TEXT},
      {dow: "Wednesday", hours: CLOSED_TEXT},
      {dow: "Thursday", hours: CLOSED_TEXT},
      {dow: "Friday", hours: CLOSED_TEXT},
      {dow: "Saturday", hours: CLOSED_TEXT},
    ]
  };

  updateOpHours = opHours => {
    // update this.state for each dowHour that is different
    opHours.forEach(dowHour => {
      const dow = moment(dowHour.start_time_local).utc().format('dddd');
      const dowNum = moment(dowHour.start_time_local).utc().format('d');
      const start_hour = moment(dowHour.start_time_local).utc().format('h:mma');
      const end_hour = moment(dowHour.end_time_local).utc().format('h:mma');
      const newOpHours = this.state.opHours;

      newOpHours[dowNum] = {
        dow: dow,
        hours: start_hour + ' - ' + end_hour
      };
      this.setState({opHours: newOpHours});
    });
  }

  collapseOpHours = () => {
    let currOpHours = this.state.opHours;

    let start = 0;
    let end = 0;

    for (let i = 0; i < currOpHours.length;) {
      if (currOpHours[i].hours === currOpHours[start].hours) { // i hours === start hours -> ++end
        end = i;
        ++i;
      }
      else if (currOpHours[i].hours !== currOpHours[start].hours) { // i hours !== start hours -> check if needa collaspse
        if (start !== end ) { // collapse if period > 1 day
          const dist = end - start;
          const dowPeriod = currOpHours[start].dow.substring(0, 3) + ' - ' + currOpHours[end].dow.substring(0, 3);
          const dowPeriodHours = currOpHours[start].hours;
          const front = currOpHours.slice(0, start);
          const back = currOpHours.slice(end + 1);
          const mid = [{dow: dowPeriod, hours: dowPeriodHours}];
          currOpHours = front.concat(mid);
          currOpHours = currOpHours.concat(back);
          start = i - dist;
          end = i - dist;
          i = i - dist;
        }
        else { // no need to collapse, reset i
          start = i;
          end = i;
        }
      }
    }
    if (start !== end ) { // check end case
      const dowPeriod = currOpHours[start].dow.substring(0, 3) + ' - ' + currOpHours[end].dow.substring(0, 3);
      const dowPeriodHours = currOpHours[start].hours;
      const front = currOpHours.slice(0, start);
      const back = currOpHours.slice(end + 1);
      const mid = [{dow: dowPeriod, hours: dowPeriodHours}];
      currOpHours = front.concat(mid);
      currOpHours = currOpHours.concat(back);
    }
    this.setState({opHours: currOpHours}); // update state at the end
  }

  componentDidMount() { // pull api data
    const location_id = this.props.location_id;

    const url = "https://api.ctrlv.ca/api/booking/getOperationHours/" + location_id;

    fetch(url)
      .then(res => res.json())
      .then(res => {
        if (res.result.response) {
          const opHours = res.result.response;
          this.updateOpHours(opHours);
        }
        this.collapseOpHours();
      });
  }

  render() {
    return ( // passing state.opHours as props
      <div id="FacilityHoursID" className='FacilityHours'>
        <OpHoursTable opHours={this.state.opHours} />
      </div>
    );
  }
}
