import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import Escapes from '../../../translations/escape_rooms.json';
import DynamicImg from '../../dynamic_image/DynamicImg';

import '../GenericPage.css';
import '../escape_rooms/EscapeRooms.css';

import { getExtension, getBaseName } from '../../../utilities/GlobalConsts';
import {Helmet} from 'react-helmet';

const xavrImg = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/xavr.png';
const EscapeBackground = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Escape%20Room%20Background';
const EscapeHeader = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Escape%20Room%20Header%20Image';
const Alice = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Alice%20Background%20Image';
const AliceSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Alice%20Screenshot';
const AvoSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/AVO%20Escape%20Space%20Screenshot';
const AvoBackground = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/AVO%20Escape%20Space%20Background';
const Belko = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Belko%20VR%20An%20Escape%20Room%20Experiment%20Background%20Image';
const BelkoSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Belko%20VR%20An%20Escape%20Room%20Experiment%20Screenshot';
const Chernobyl = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Chernobyl%20Background%20Image';
const ChernobylSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Chernobyl%20Screenshot';
const Christmas = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Christmas%20Background%20Image';
const ChristmasSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Christmas%20Screenshot';
const Cyberpunk = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Cyberpunk%20Background%20Image';
const CyberpunkSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Cyberpunk%20Screenshot';
const Bloody = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Escape%20Bloody%20Mary%20Background%20Image';
const BloodySS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Escape%20Bloody%20Mary%20Screenshot';
const Jungle = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Jungle%20Quest%20Background%20Image';
const JungleSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Jungle%20Quest%20Screenshot';
const Lockdown = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Kidnapped%20Background%20Image';
const LockdownSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Kidnapped%20Screenshot';
const Lockdown2 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Circus%20of%20the%20Dead%20Background%20Image';
const Lockdown2SS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Circus%20of%20the%20Dead%20Screenshot';
const Lockdown3 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Forgotten%20Temple%20Background%20Image';
const Lockdown3SS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Lockdown%20VR%20Forgotten%20Temple%20Screenshot';
const Sigma = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Mission%20Sigma%20Background%20Image';
const SigmaSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Mission%20Sigma%20Screenshot';
const Paranormal = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Paranormal%20Detective%20Escape%20from%20the%2080s%20Background%20Image';
const ParanormalSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Paranormal%20Detective%20Escape%20from%20the%2080s%20Screenshot';
const Paranormal2 = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Paranormal%20Detective%20Escape%20from%20the%2090s%20Background%20Image';
const Paranormal2SS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Paranormal%20Detective%20Escape%20from%20the%2090s%20Screenshot';
const Sanctum = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Sanctum%20Background%20Image';
const SanctumSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Sanctum%20Screenshot';
const Survival = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Survival%20Background%20Image';
const SurvivalSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Survival%20Screenshot';
const Tales = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tales%20of%20Escape%20Background%20Image';
const TalesSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/Tales%20of%20Escape%20Screenshot';
const Prison = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/The%20Prison%20Background%20Image';
const PrisonSS = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/The%20Prison%20Screenshot';

class EscapeRooms extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(Escapes);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Escape Rooms | Ctrl V - Virtual Reality Arcade</title>
                    <meta name="description" content="The best VR escape rooms you'll ever try!"/>
                    <link rel="canonical" href={`${getBaseName()}/escape-rooms/`} />
                    <meta property="og:title" content="Escape Rooms | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="The best VR escape rooms you'll ever try!" />
                    <meta property="og:url" content={`${getBaseName()}/escape-rooms/`}/>
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="The best VR escape rooms you'll ever try!" />
                    <meta name="twitter:title" content="Escape Rooms | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content={`escape,rooms,room,group,puzzle,chamber`}/>
                </Helmet>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${EscapeBackground}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className='escape-title'>
                        <Translate id="title.top" />
                    </div>
                    <div className='top-image-section'>
                        <DynamicImg
                            imageName={EscapeHeader}
                            fallbackExt='png'
                            alt="Jungle Quest Screenshot"
                            width="100%"
                            height="auto"
                            imgClass="escape-image"
                        />
                    </div>
                    <div className='escape-paragraph-section'>
                        <div className='escape-paragraph'><Translate id="title.middle" /></div>
                        <br></br>
                        <div className='escape-paragraph'><Translate id="title.middle-2" /></div>
                        <a href="https://www.ctrlv.ca/booking">
                            <button className="escape-button generic-pulse-animation">
                                BOOK NOW
                            </button>
                        </a>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Alice}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="alice.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="alice.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="alice.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="alice.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="alice.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={AliceSS}
                                        fallbackExt='png'
                                        alt="Alice Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="alice.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/VIjydDqytVg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Chernobyl}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/3P0hES8sQBU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="chernobyl.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="chernobyl.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="chernobyl.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="chernobyl.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="chernobyl.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={ChernobylSS}
                                        fallbackExt='png'
                                        alt="Chernobyl Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="chernobyl.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/3P0hES8sQBU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Christmas}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="christmas.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="christmas.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="christmas.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="christmas.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="christmas.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={ChristmasSS}
                                        fallbackExt='png'
                                        alt="Christmas Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="christmas.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/oElc7Z4HITQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Cyberpunk}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/03nVD1Zye18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="cyberpunk.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="cyberpunk.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="cyberpunk.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="cyberpunk.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="cyberpunk.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={CyberpunkSS}
                                        fallbackExt='png'
                                        alt="Cyberpunk Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="cyberpunk.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/03nVD1Zye18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Jungle}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="jungle.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="jungle.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="jungle.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="jungle.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="jungle.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={JungleSS}
                                        fallbackExt='png'
                                        alt="Jungle Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="jungle.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/5xytZTgFoog" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Sigma}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/crkjQtRChU4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="sigma.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sigma.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sigma.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sigma.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sigma.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={SigmaSS}
                                        fallbackExt='png'
                                        alt="Sigma Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="sigma.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/crkjQtRChU4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Sanctum}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="sanctum.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sanctum.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sanctum.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sanctum.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="sanctum.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={SanctumSS}
                                        fallbackExt='png'
                                        alt="Sanctum Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="sanctum.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/9FtWnqSPuvI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Survival}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/obLd4tLRKIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="survival.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="survival.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="survival.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="survival.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="survival.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={SurvivalSS}
                                        fallbackExt='png'
                                        alt="Survival Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="survival.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/obLd4tLRKIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Prison}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="prison.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="prison.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="prison.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="prison.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="prison.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={PrisonSS}
                                        fallbackExt='png'
                                        alt="Prison Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="prison.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/U4sAOszTNz0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${AvoBackground}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/rnR1K-rEPEA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="avo.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="avo.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="avo.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="avo.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="avo.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={AvoSS}
                                        fallbackExt='png'
                                        alt="AVO Escape Space Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="avo.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/rnR1K-rEPEA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Lockdown}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="lockdown.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading3'>
                                        <Translate id="lockdown.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={LockdownSS}
                                        fallbackExt='png'
                                        alt="Lockdown VR: Kidnapped Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="lockdown.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/Mq7TigTUHd8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Lockdown2}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/ThrikvfiTDM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="lockdown2.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown2.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown2.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown2.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading3'>
                                        <Translate id="lockdown2.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={Lockdown2SS}
                                        fallbackExt='png'
                                        alt="Lockdown VR: Circus of the Dead Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="lockdown2.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/ThrikvfiTDM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Lockdown3}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="lockdown3.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown3.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown3.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="lockdown3.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading3'>
                                        <Translate id="lockdown3.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={Lockdown3SS}
                                        fallbackExt='png'
                                        alt="Lockdown VR: Forgotten Temple Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="lockdown3.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/l9UvLncd2OI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Paranormal}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/qe0eDWZWcQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="paranormal.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={ParanormalSS}
                                        fallbackExt='png'
                                        alt="Paranormal Detective 80s Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="paranormal.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/qe0eDWZWcQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Paranormal2}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="paranormal2.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal2.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal2.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal2.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="paranormal2.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section2'>
                                    <DynamicImg
                                        imageName={Paranormal2SS}
                                        fallbackExt='png'
                                        alt="Paranormal Detective 90s Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="paranormal2.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/qt215mOINOo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Bloody}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/E739o2gZVPI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="bloody.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="bloody.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="bloody.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="bloody.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading3'>
                                        <Translate id="bloody.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={BloodySS}
                                        fallbackExt='png'
                                        alt="Bloody Mary Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="bloody.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/E739o2gZVPI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Tales}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="tales.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="tales.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="tales.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="tales.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="tales.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section2'>
                                    <DynamicImg
                                        imageName={TalesSS}
                                        fallbackExt='png'
                                        alt="Tales of Escape Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="tales.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section'>
                            <div className="escape-sub-section3">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/UE7lXcJQUpU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parallax-wrapper" style={{backgroundImage: `url(${isBrowser ? `${Belko}.${getExtension()})` : `${xavrImg}.${getExtension()})`}`}}>
                    <div className="escape-section">
                        <div className='escape-sub-section-ytl'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/u3s7ot_YvkQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="escape-sub-section">
                            <div className="escape-heading">
                                <Translate id="belko.title" />
                            </div>
                            <div className='escape-info-section'>
                                <div className="escape-sub-heading-section">
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.difficulty" />                                        
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="belko.difficulty" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.players" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="belko.players" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.age" />
                                    </div>
                                    <div className='escape-sub-heading2'>
                                        <Translate id="belko.age" />
                                    </div>
                                    <div className='escape-sub-heading'>
                                        <Translate id="headings.developer" />
                                    </div>
                                    <div className='escape-sub-heading3'>
                                        <Translate id="belko.developer" />
                                    </div>
                                </div>
                            </div>
                            <div className='escape-info-section'>
                                <div className='escape-image-section'>
                                    <DynamicImg
                                        imageName={BelkoSS}
                                        fallbackExt='png'
                                        alt="Belko Screenshot"
                                        width="100%"
                                        height="auto"
                                        imgClass="escape-image"
                                    />
                                </div>
                            </div>
                            <div className="escape-description">
                                <Translate id="belko.description" />
                            </div>
                        </div>
                        <div className='escape-sub-section-ytl2'>
                            <div className="escape-sub-section2">
                                <div className='escape-sub-section4'>
                                    <iframe className="yt-iframe" src="https://www.youtube.com/embed/u3s7ot_YvkQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withLocalize(EscapeRooms);