import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Show404 from '../pages/show404/Show404';

class RedirectHandler extends Component {

    // these redirects were used in promotions and on past pages, so we need to redirect them to the new structure
    render() {
        return(
            <Switch>
                <Route path="/waterloo/birthdays" render={() => <Redirect to="/birthdays/waterloo" />} />
                <Route path="/waterloo/events" render={() => <Redirect to="/events/waterloo" />} />
                <Route path="/waterloo" render={() => <Redirect to="/location/waterloo" />} />
                <Route path="/ctrl-v-waterloo-flagship/" render={() => <Redirect to="/location/waterloo" />} />

                <Route path="/guelph/birthdays" render={() => <Redirect to="/birthdays/guelph" />} />
                <Route path="/guelph/events" render={() => <Redirect to="/events/guelph" />} />
                <Route path="/guelph" render={() => <Redirect to="/location/guelph" />} />
                <Route path="/ctrl-v-guelph-victoria-york/" render={() => <Redirect to="/location/guelph" />} />

                <Route path="/red-deer/birthdays" render={() => <Redirect to="/birthdays/red-deer" />} />
                <Route path="/red-deer/events" render={() => <Redirect to="/events/red-deer" />} />
                <Route path="/red-deer" render={() => <Redirect to="/location/red-deer" />} />
                <Route path="/ctrl-v-red-deer-carnival-cinemas/" render={() => <Redirect to="/location/red-deer" />} />

                <Route path="/lethbridge/birthdays" render={() => <Redirect to="/birthdays/lethbridge" />} />
                <Route path="/lethbridge/events" render={() => <Redirect to="/events/lethbridge" />} />
                <Route path="/lethbridge" render={() => <Redirect to="/location/lethbridge" />} />
                <Route path="/ctrl-v-lethbridge-south-lethbridge/" render={() => <Redirect to="/location/lethbridge" />} />

                <Route path="/edmonton-west/birthdays" render={() => <Redirect to="/birthdays/edmonton-west" />} />
                <Route path="/edmonton-west/events" render={() => <Redirect to="/events/edmonton-west" />} />
                <Route path="/edmonton-west" render={() => <Redirect to="/location/edmonton-west" />} />
                <Route path="/ctrl-v-edmonton-edmonton-west/" render={() => <Redirect to="/location/edmonton-west" />} />

                <Route path="/hamilton/birthdays" render={() => <Redirect to="/birthdays/hamilton" />} />
                <Route path="/hamilton/events" render={() => <Redirect to="/events/hamilton" />} />
                <Route path="/hamilton" render={() => <Redirect to="/location/hamilton" />} />
                <Route path="/ctrl-v-hamilton-stoney-creek/" render={() => <Redirect to="/location/hamilton" />} />

                <Route path="/winnipeg/birthdays" render={() => <Redirect to="/birthdays/winnipeg" />} />
                <Route path="/winnipeg/events" render={() => <Redirect to="/events/winnipeg" />} />
                <Route path="/winnipeg" render={() => <Redirect to="/location/winnipeg" />} />
                <Route path="/ctrl-v-winnipeg-winnipeg-west/" render={() => <Redirect to="/location/winnipeg" />} />

                <Route path="/plano/birthdays" render={() => <Redirect to="/birthdays/plano" />} />
                <Route path="/plano/events" render={() => <Redirect to="/events/plano" />} />
                <Route path="/plano" render={() => <Redirect to="/location/plano" />} />

                <Route path="/gift-card" render={() => <Redirect to="/gift-cards" />} />
                <Route path="/contests" render={() => <Redirect to="/legal/contest-rules" />} />
                <Route path="/sweepstakes" render={() => <Redirect to="/legal/sweepstakes-rules" />} />
                <Route path="/account" render={() => <Redirect to="/accounts" />} />

                <Route path="/anniversary" render={() => <Redirect to="/annivrsary" />} />

                <Route path="/" render={() => <Show404 />} />
            </Switch>
        )
    }
}

export default RedirectHandler;
