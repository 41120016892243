import React, { Component } from 'react';
import { isBrowser } from 'react-device-detect';

import { withLocalize, Translate } from 'react-localize-redux';
import LivestreamPromoTranslation from '../../../translations/livestream_promo.json';

import { getBaseName } from '../../../utilities/GlobalConsts';

import '../GenericPage.css';
import DynamicImg from '../../dynamic_image/DynamicImg.js';
import {Helmet} from 'react-helmet';

const LiveStreamPromoImage = 'https://storage.googleapis.com/vault.api.ctrlv.ca/public/static_images/website/live_stream_promo';

class LiveStreamPromo extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(LivestreamPromoTranslation);
    }

    render() {
        return(
            <div className={isBrowser ? "generic-wrapper browser-wrapper" : "generic-wrapper"}>
                
                <Helmet>
                    <title>Live Stream Promotion | Ctrl V</title>
                    <meta name="description" content="Receive a wicked gift and give yourself the perfect souvenir while sharing your epic VR experience with your friends and family!"/>
                    <link rel="canonical" href={`${getBaseName()}/live-stream-promo/`} />
                    <meta property="og:title" content="Live Stream Promotion | Ctrl V - Virtual Reality Arcade" />
                    <meta property="og:description" content="Receive a wicked gift and give yourself the perfect souvenir while sharing your epic VR experience with your friends and family!" />
                    <meta property="og:url" content={`${getBaseName()}/live-stream-promo/`} />
                    <meta property="og:image" content="https://storage.googleapis.com/vault.api.ctrlv.ca/public/meta-images/aisle-meta.png" />
                    <meta name="twitter:description" content="Receive a wicked gift and give yourself the perfect souvenir while sharing your epic VR experience with your friends and family!" />
                    <meta name="twitter:title" content="Live Stream Promotion | Ctrl V - Virtual Reality Arcade" />
                    <meta name="keywords" content="live,stream,promotion,youtube,instagram,twitch,facebook" />
                </Helmet>

                <div className="generic-section">
                    <div className="align-center">
                        <Translate id="section-1.top-line" />
                    </div>
                </div>

                <div className="generic-heading">
                    <Translate id="section-1.title" />
                </div>

                <DynamicImg
                    imageName={LiveStreamPromoImage}
                    fallbackExt='jpg'
                    imgClass="generic-image"
                    alt="Live Stream Promo"
                    width="100%"
                    height="auto"
                />

                <div className="generic-section">
                    <Translate id="section-2.book" />

                    <span className="youtube-text">YouTube</span>, 
                    <span className="facebook-text"> Facebook</span>, 
                    <span className="twitch-text"> Twitch</span> 
                    <Translate id="section-2.or" />
                    <span className="instagram-text"> Instagram</span>

                    <Translate id="section-2.account-and-receive" />
                </div>

                <div className="generic-header blue-text">
                    <Translate id="section-2.loyalty-points" />
                </div>

                <div className="generic-section">
                    <div className="align-center">
                        <Translate id="section-2.great-deal" />
                    </div>
                </div>

                <div className="generic-footnote">
                    <div className="align-center">
                        <Translate id="section-2.foot-notes" />
                    </div>
                </div>

            </div>
        );
    }
}

export default withLocalize(LiveStreamPromo);